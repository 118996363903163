import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart, Brush } from 'recharts';
import TrendAxisPopover from './TrendAxisPopover';
import { leftAxis, rightAxis } from '../../forms/admin/trend_axis';

import ChartBase from './ChartBase';

class TrendAnalysisTopChart extends ChartBase {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      settings: {},
      chartKey: uuidv4(),
      chartData: [],
      left_axis: cloneDeep(leftAxis),
      right_axis: cloneDeep(rightAxis),
      chartPosition: {}
    };

    this.buildChartData = this.buildChartData.bind(this);
    this.updateField = this.updateField.bind(this);
    this.toggleChartPosition = this.toggleChartPosition.bind(this);
    this.setChartSettings = this.setChartSettings.bind(this);
  }

  componentDidMount() {
    const { row } = this.props;

    this.setChartSettings(row);
    this.toggleChartPosition();
  }

  setChartSettings(row) {
    const settings = this.buildChartSettings(row);
    this.setState({settings, chartKey: uuidv4()});
  }

  componentDidUpdate(prevProps, prevState) {
    const { settings } = this.state;
    const newSettings = this.props.settings;

    const propsChanged = (JSON.stringify(prevProps.settings) !== JSON.stringify(newSettings));
    if (propsChanged) {
      this.setState({settings: newSettings});
      this.buildChartData(newSettings);
    }

    const stateChanged = (JSON.stringify(prevState.settings) !== JSON.stringify(settings));
    if (stateChanged) {
      this.buildChartData(settings);
    }
  }

  updateField(record, field_name, value) {
    const { settings } = this.state;

    if (record.property_id) {
      settings[record.orientation].properties
      .find(property => property.id === record.property_id).fields
      .find(field => field.dataKey === record.dataKey)[field_name] = value;
    }

    if (record.group_id) {
      settings[record.orientation].groups
      .find(group => group.id === record.group_id).fields
      .find(field => field.dataKey === record.dataKey)[field_name] = value;
    }

    this.setState({settings, chartKey: uuidv4()});
  }

  toggleChartPosition() {
    const relative = {
      position: 'relative',
      buttonName: 'expand',
      buttonTitle: 'Maximise Chart',
      buttonCaption: 'Expand',
      height: '100%'
    };

    const absolute = {
      position: 'absolute',
      buttonName: 'compress',
      buttonTitle: 'Compress Chart',
      buttonCaption: 'Compress',
      height: '80%'
    };

    this.setState({chartPosition: (this.state?.chartPosition?.position === 'relative' ? absolute : relative)});

    this.props.toggleTopChartOnly((this.state?.chartPosition?.position === 'relative'));
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    const chartFontSize = 13;
    const { left_axis, right_axis, chartKey, chartData, settings, chartPosition } = this.state;
    const { lookupMetrics } = this.props.reports;
    const { config, flattenedProbeRows } = this.props;

    return (
      <div style={{height: chartPosition.height, width: '100%', fontSize: chartFontSize, position: chartPosition.position, top: 0, left: 0}} className="p-1 m-1 border border-lightgray rounded bg-light">
        <div className="d-flex justify-content-between">
          <TrendAxisPopover
            axis={settings.leftAxis}
            controls={left_axis}
            title="Graph Display Settings"
            orientation='leftAxis'
            metrics={lookupMetrics}
            flattenedProbeRows={flattenedProbeRows}
            updateField={this.updateField}
            handleMetricChange={this.setChartSettings}
          />
          <div className="text-center mt-1" style={{fontSize: '1.25rem'}}>{settings?.leftAxis?.title}</div>
          <div>
            {/* <Label style={{fontSize: 14}} className="text-primary">{chartPosition.buttonCaption}</Label> */}
            <Button size="sm" color="link" onClick={this.toggleChartPosition}>
              {chartPosition.buttonCaption}
              <Icon
                id={chartPosition.buttonName}
                name={chartPosition.buttonName}
                title={chartPosition.buttonTitle}
                className="text-primary ml-2"
              />
            </Button>
          </div>

          {/* <TrendAxisPopover
            axis={settings.rightAxis}
            controls={right_axis}
            title="Right Axis"
            orientation='rightAxis'
            metrics={lookupMetrics}
            updateField={this.updateField}
            handleColourChange={this.handleColourChange}
          /> */}
        </div>

        <ResponsiveContainer width="100%" height="95%" minWidth={400} minHeight={300} key={uuidv4()} >
          <ComposedChart
            // width={500}
            height={400}
            data={chartData}
            syncId="anyId"
            margin={{
              top: 5,
              right: 5,
              left: 20,
              bottom: 15,
            }}
            key={chartKey}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis
              dataKey="name"
              style={{fontSize: chartFontSize}}
            />

            <YAxis
              label={{ value: '', angle: -90, position: 'insideCenter' }}
              yAxisId="left"
              style={{fontSize: chartFontSize}}
              width={100} 
              tickFormatter={(value) => this.tickFormatter(value,settings?.leftAxis?.unit_attributes)}
              domain={['auto', 'auto']}
            />

            <YAxis
              label={{ value: '', angle: 90, position: 'outsideCenter' }}
              orientation="right"
              yAxisId="right"
              style={{fontSize: chartFontSize}}
            />

            {config.showTooltip && (
              <Tooltip style={{fontSize: chartFontSize}} formatter={(value) => this.tickFormatter(value,settings?.leftAxis?.unit_attributes)}/>
            )}

            <Legend wrapperStyle={{fontSize: chartFontSize, padding: 3}} verticalAlign='top' margin={{ top: 20, left: 20, right: 20, bottom: 20 }} />

            {this.renderChart()}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

const mapStoreToProps = ({ trend_analysis, warehouse, analysis_groups, reports, probe }) => ({
  warehouse,
  trend_analysis,
  analysis_groups,
  reports,
  probe
});

export default connect(mapStoreToProps)(TrendAnalysisTopChart);
