import { connect } from 'react-redux';
import FormBase from 'jsx/components/core/form/components/FormBase';
import { v4 as uuidv4 } from 'uuid';
import Pill from 'jsx/components/core/form/components/Pill';
import { Row, Col } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

import { fetchAnalysisGroupMembers } from 'jsx/components/manage/actions/analysis_group_members';
import { fetchWarehouseProperty, fetchWarehouseGroup, fetchWarehouseGroupsMinMax } from '../../actions/warehouse';

import TrendAnalysisChart from '../../components/trendanalysis/TrendAnalysisChart';
import TrendAnalysisTopChart from '../../components/trendanalysis/TrendAnalysisTopChart';

import AdminTrendAnalysisToolbar from '../../components/trendanalysis/TrendAnalysisToolbar';
import { controls as trendControls } from '../../forms/admin/trend_filters';

import {
  fetchProbeTemplateByTag,
  updateProbeTemplate
} from '../../actions/admin/probe';

class TrendAnalysis extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      probe_tag: 'standard_probe',
      trendFilterControls: trendControls,
      chartData: [],
      topChartRow: [],
      showTopChartOnly: false,
      chartKey: uuidv4(),
      subChartKey: uuidv4(),
      settings: {},
      config: {showTooltip: true, syncTip: true},
      title: '',
      selectedPropertyId: null
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.setMainChart = this.setMainChart.bind(this);
    this.onHandleConfigChange = this.onHandleConfigChange.bind(this);
    this.toggleTopChartOnly = this.toggleTopChartOnly.bind(this);
    this.updateFieldDefault = this.updateFieldDefault.bind(this);
    this.setChartDefaults = this.setChartDefaults.bind(this);
  }

  async componentDidMount() {
    const probeTemplate = await this.props.dispatch(fetchProbeTemplateByTag(this.state.probe_tag));
    this.setDefaultRow(probeTemplate);
    this.setChartDefaults(probeTemplate);
  }

  setChartDefaults(probeTemplate) {
    const attributes = probeTemplate.attributes || {};

    if (attributes.trend_analysis.defaults.propertyFields) {
      this.props.dispatch({type: 'SET_TREND_PROPERTY_FIELD_DEFAULT_FULFILLED', payload: attributes.trend_analysis.defaults.propertyFields});
    }

    if (attributes.trend_analysis.defaults.groupFields) {
      this.props.dispatch({type: 'SET_TREND_GROUP_FIELD_DEFAULT_FULFILLED', payload: attributes.trend_analysis.defaults.groupFields});
    }
  }

  componentDidUpdate() {
    const { selectedPropertyId } = this.props;
    const { filters } = this.props.warehouse;

    if ( !this.state.selectedPropertyId && selectedPropertyId && filters.property_id.length === 0 ) {
      this.setState({selectedPropertyId});
      this.handleFilterChange({value: selectedPropertyId}, 'property_id');
    }
  }

  toggleTopChartOnly(value) {
    this.setState({showTopChartOnly: value});
  }

  updateFieldDefault(record, field_name, value) {
    const { propertyFields, groupFields } = this.props.trend_analysis;
    const { probeTemplate } = this.props.probe;
    const attributes = probeTemplate.attributes || {};

    if (record.field_type === 'property') {
      propertyFields.find(field => field.dataKey === record.dataKey && field.index === record.index)[field_name] = value;
      this.props.dispatch({type: 'SET_TREND_PROPERTY_FIELD_DEFAULT_FULFILLED', payload: propertyFields});
      attributes.trend_analysis.defaults.propertyFields = propertyFields;

      const data = {id: probeTemplate.id, attributes};
      this.props.dispatch(updateProbeTemplate(data));
    }

    if (record.field_type === 'group') {
      groupFields.find(field => field.dataKey === record.dataKey && field.index === record.index)[field_name] = value;
      this.props.dispatch({type: 'SET_TREND_GROUP_FIELD_DEFAULT_FULFILLED', payload: groupFields});
      attributes.trend_analysis.defaults.groupFields = groupFields;

      const data = {id: probeTemplate.id, attributes};
      this.props.dispatch(updateProbeTemplate(data));
    }
  }

  setDefaultRow(probe) {
    const rows = this.flattenProbe(probe);

    // look for return_on_assets as default
    let defaultRow = rows.find(row => row.metric.key === 'return_on_assets');

    // if cant be found, return first row as default
    if (!defaultRow && rows.length > 0) defaultRow = rows[3];
    this.setState({flattenedProbeRows: rows, topChartRow: defaultRow});
  }

  // eslint-disable-next-line class-methods-use-this
  flattenProbe(probe) {
    const rows = [];

    probe?.sections?.map(section => {
      section.groups.map(group => {
        group?.rows.map(row => {
          rows.push({...row, group})
        });
      });
    });

    return rows;
  }

  onHandleConfigChange(event) {
    const { config } = this.state;
    config[event.target.name] = event.target.checked;
    this.setState({config, chartKey: uuidv4(), subChartKey: uuidv4()});
  }

  setMainChart(settings) {
    this.setState({settings});
  }

  async handleFilterChange(event, field) {
    const { filters } = this.props.warehouse;
    const { value } = event;
    const { trendFilterControls } = this.state;
    const { probeTemplate } = this.props.probe;

    // Fetch Properties
    const property_id = value;
    filters[field] = [value];
    const probeRows = await this.props.dispatch(fetchWarehouseProperty('_all', property_id));

    // Filter Flattened Probe rows
    const kpiGroupIds = [...new Set(probeRows.map(probeRow => probeRow.kpigroup_id))];

    const flattenedProbeRows = this.flattenProbe(probeTemplate)?.filter(row => kpiGroupIds.includes(row.group_id))

    // Fetch Groups enabled for property
    const groups = await this.props.dispatch(fetchAnalysisGroupMembers({property_id}));
    trendFilterControls.group_id.options = groups.rows.map(row => ({id: row?.group?.id, name: row?.group?.name}));

    let group_id = null;
    if (groups.count > 0) {
      group_id = groups.rows[0].analysis_group_id;
      trendFilterControls.group_id.value = group_id;
      filters.group_id = [group_id];

      await this.props.dispatch(fetchWarehouseGroup('_all', group_id));
      await this.props.dispatch(fetchWarehouseGroupsMinMax('_all', group_id, '_all'));
    };

    this.setState({chartKey: uuidv4(), subChartKey: uuidv4(), trendFilterControls, flattenedProbeRows});
    this.props.dispatch({type: 'SET_WAREHOUSE_FILTER_FULFILLED', payload: filters});
  }

  async onHandleFilterDelete(field, row) {
    const { filters } = this.props.warehouse;

    const idx = filters[field].findIndex(filter => filter === row.id);
    filters[field].splice(idx, 1);

    this.props.dispatch({type: 'SET_WAREHOUSE_FILTER_FULFILLED', payload: filters});
  };


  renderCells(group) {
    const smallChartSize = 4;

    // eslint-disable-next-line arrow-body-style
    const cells = group.rows.map((row, index) => {
      return (
        <Col key={index} sm={smallChartSize} md={smallChartSize} lg={smallChartSize} className="m-0 p-1" style={{height: 400}} >
          <TrendAnalysisChart
            key={this.state.subChartKey}
            title={`${group.caption} - ${row.metric.name}`}
            row={{...row, group}}
            setMainChart={this.setMainChart}
            config={this.state.config}
          />
        </Col>
      );
    });

  return cells;
  }

  renderGroups(section) {
    const { probeRows } = this.props.warehouse;
    
    // eslint-disable-next-line consistent-return, arrow-body-style
    const groups = [];
    section.groups.map((group, index) => {
      const probeGroup = probeRows.filter(probeRow => probeRow.kpigroup_id === group.id);

      if (probeGroup.length > 0) {
        groups.push(
          <div key={index} className="mb-2">
            <div className="bg-orange-20 p-1 border-bottom border-orange pl-2" style={{fontSize: 14}}>{group.caption}</div>
            <Row className="m-0 p-0">
              {this.renderCells(group)}
            </Row>
          </div>
        );
      }
    });

    return groups;
  }

  renderSections() {
    const {
      probeTemplate
    } = this.props.probe;

    if (this.state.showTopChartOnly) return <></>;

    return probeTemplate?.sections?.map(section => (
        <>
          <div className="bg-green-20 p-1 mt-2 border-bottom border-green-40 pl-2" style={{fontSize: 20}}>{section.caption}</div>
          {this.renderGroups(section)}
      </>
      )
    );
  }

  renderPropertyFilters() {
    const { distinct_properties, filters } = this.props.warehouse;

    const pills = filters.property_id.map((property_id, index) => {
      const property = distinct_properties.find(distinct_property => distinct_property.id === property_id);

      return (
        <Pill
          classes="bg-pink"
          caption={`PROPERTY: ${property?.name}`}
          onDelete={() => this.onHandleFilterDelete('property_id', property)}
          key={index}
        />
      );
    });

    return pills;
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    const { distinct_properties, filters } = this.props.warehouse;
    const { propertyFields, groupFields } = this.props.trend_analysis;
    const {
      trendFilterControls,
      config,
      settings,
      topChartRow,
      chartKey,
      showTopChartOnly,
      flattenedProbeRows
    } = this.state;

    return (
      <div className="m-0 p-0">
        {!showTopChartOnly && (
          <AdminTrendAnalysisToolbar
            properties={distinct_properties}
            handleFilterChange={this.handleFilterChange}
            onHandleConfigChange={this.onHandleConfigChange}
            config={config}
            filterControls={trendFilterControls}
            propertyFilters={this.renderPropertyFilters()}
            buttonsDisabled={(filters.property_id.length === 0)}
            showDefaultsButton={this.props.isAdmin}
            filters={filters}
            propertyFields={propertyFields}
            groupFields={groupFields}
            updateField={this.updateFieldDefault}
          />
        )}

        {filters.property_id.length > 0 && (
          <>
            <Row className="m-0 p-0">
              <Col className="m-0 p-1" style={{height: 400, position: 'unset'}}>
                <TrendAnalysisTopChart
                  settings={settings}
                  row={topChartRow}
                  key={chartKey}
                  config={config}
                  toggleTopChartOnly={this.toggleTopChartOnly}
                  flattenedProbeRows={flattenedProbeRows}
                />
              </Col>
            </Row>

            {this.renderSections()}
          </>
        )}

        {filters.property_id.length === 0 && (
          <div className="text-center p-4">
            <div>
              <Icon
                as="button"
                name="chart-line"
                size="3x"
                className="ml-2 p-0"
              />
            </div>
            <h3 className="mt-3">Trend Analysis</h3>
            <div>Select Property to get started...</div>
          </div>

        )}
      </div>
    );
  }
}

const mapStoreToProps = ({ analysis_periods, analysis_groups, warehouse, probe, trend_analysis }) => ({
  analysis_periods,
  analysis_groups,
  warehouse,
  probe,
  trend_analysis
});

export default connect(mapStoreToProps)(TrendAnalysis);
