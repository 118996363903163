import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button, Label } from 'reactstrap';
import FormBase from 'jsx/components/core/form/components/FormBase';
import FormInput from 'jsx/components/core/form/components/FormInput';
import FormInputSwitch from 'jsx/components/core/form/components/FormInputSwitch';
import Icon from 'jsx/components/core/icons/Icon';
import { cloneDeep } from 'lodash';
import { controls as schedulerControls } from '../../forms/admin/scheduler';
import {
  // scheduleAllPropertyProbes,
  schedulePropertyProbes,
  scheduleGroup1year,
  scheduleTop20,
  schedule3yrTop20
} from '../../actions/admin/scheduler';
import { fetchStats } from '../../actions/admin/scheduler';
import { fetchWarehouseEligibleProperties } from '../../actions/warehouse';
import Pill from 'jsx/components/core/form/components/Pill';

class AdminProbeSchedulerModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
        isValid: true,
        isOpen: false,
        data: {},
        id: null,
        isNew: false,
        title: '',
        setModal: null,
        controls: cloneDeep(schedulerControls),
        selectedProperties: []
    };

    this.onClose = this.onClose.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onHandleTypeChange = this.onHandleTypeChange.bind(this);
    this.onHandlePeriodChange = this.onHandlePeriodChange.bind(this);
    this.renderProperties = this.renderProperties.bind(this);
    this.onHandlePropertySelect = this.onHandlePropertySelect.bind(this);
    this.onHandlePropertyDelete = this.onHandlePropertyDelete.bind(this);
    this.onHandleIgnoreCache = this.onHandleIgnoreCache.bind(this);
  }

  async componentDidUpdate() {
    const { isOpen, controls } = this.state;
    let { selectedProperties } = this.state;
    let { title, data} = this.state;
    const { fetching } = this.props.warehouse;
    const { periods } = this.props.analysis_periods;
    const { groups } = this.props.analysis_groups;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      this.setState({isOpen: true});
      title = 'Schedule Job';
      data = {};

      controls.period_id.options = periods.rows.map(period => ({id: period.id, name: period.probe_period}));
      controls.group_id.options = groups.rows.map(group => ({id: group.id, name: group.name}));

      controls.period_id.value = controls.period_id.options[0].id;
      controls.type_id.value = controls.type_id.options[0].id;
      controls.group_id.value = controls.group_id.options[0].id;

      if (this.props.schedulerProperty.id) {
        controls.period_id.value = this.props.schedulerPeriodId;
        controls.property_id.options = await this.fetchEligibleProperties(controls.period_id.value);
        controls.type_id.value = 'selected_properties';

        selectedProperties = [];
        selectedProperties.push(this.props.schedulerProperty);
      };

      this.setState({
        isOpen: this.props.isOpen,
        title,
        setModal: this.props.setModal,
        data,
        selectedProperties
      });
    }
  }

  onClose(refresh = false) {
    if (refresh && this.props.onClose) this.props.onClose();
    this.state.setModal(false);
    this.setState({
      isOpen: false,
    });
  }

  async onHandleTypeChange(event) {
    const { controls } = this.state;
    controls.type_id.value = event.target.value;

    switch (event.target.value) {
      case 'selective_group_3yr':
      case 'selective_group':

        break;
      case 'all_properties':

        break;
      case 'selected_properties':
        controls.property_id.options = await this.fetchEligibleProperties(controls.period_id.value);
        controls.property_id.valid = true;

        if (controls.property_id.options.length === 1) {
          controls.property_id.message = 'No Eligible properties found for selected Analysis Period!';
          controls.property_id.valid = false;
        }

        break;
      default: break;
    }

    this.setState({controls});
  }

  async onHandlePeriodChange(event) {
    const { controls } = this.state;
    controls.period_id.value = event.target.value;

    controls.property_id.options = await this.fetchEligibleProperties(controls.period_id.value);
    controls.property_id.valid = true;

    if (controls.property_id.options.length === 1) {
      controls.property_id.message = 'No Eligible properties found for selected Analysis Period!';
      controls.property_id.valid = false;
    }

    this.setState({controls});
  };

  async onHandleIgnoreCache(event) {
    const {controls} = this.state;

    controls.ignore_cache.value = event.target.checked;
    this.setState(controls);
  }

  async onHandlePropertySelect(event) {
    const { selectedProperties } = this.state;
    const { options } = event.target;

    const selectedOption = options[options.selectedIndex];

    selectedProperties.push({id: selectedOption.value, name: selectedOption.innerHTML});
    this.setState({selectedProperties});
  }

  async onHandlePropertyDelete(row) {
    const { selectedProperties } = this.state;
    const idx = selectedProperties.findIndex(selectedProperty => selectedProperty.id === row.id);

    selectedProperties.splice(idx, 1);

    this.setState({selectedProperties});
  };

  async fetchEligibleProperties(period_id) {
    const eligibles = await this.props.dispatch(fetchWarehouseEligibleProperties(period_id));
    const noneSelected = [{id: '', 'name': 'Select Property'}];
    const options = noneSelected.concat(eligibles.map(eligible => ({id: eligible?.property?.id, name: eligible?.property?.name})));

    return options;
  }

  renderProperties() {
    const { selectedProperties } = this.state;

    return selectedProperties.map((selected, index) => (
      <Pill
        classes="bg-pink"
        key={`${selected.id} - ${index}`}
        caption={selected.name}
        onDelete={() => this.onHandlePropertyDelete(selected)}
      />
    ));
  }

  async onCreate() {
    const { controls, selectedProperties } = this.state;

    const params = {
      period_id: controls.period_id.value,
      ignore_cache: controls.ignore_cache.value
    };

    let response = {};
    switch (controls.type_id.value) {
      case 'selective_group_3yr':
        params.group_id = controls.group_id.value;
        response = await this.props.dispatch(schedule3yrTop20(params));
        break;
      case 'selective_group':
        params.group_id = controls.group_id.value;
        response = await this.props.dispatch(scheduleTop20(params));
        response = await this.props.dispatch(scheduleGroup1year(params));
        break;
      case 'all_properties':
        response = await this.props.dispatch(schedulePropertyProbes(params));
        break;
      case 'selected_properties':
        if (selectedProperties.length === 0) {
          response.message = 'Must have atleast one property selected';
        };

        params.selected_property_ids = selectedProperties.map(selected => selected.id);
        response = await this.props.dispatch(schedulePropertyProbes(params));
        break;
      case 'missing_probes':
        params.missing_probes_only = true;
        response = await this.props.dispatch(schedulePropertyProbes(params));
        break;
      default: break;
    }

    // Display response message
    // window.alert(response.message);

    // Fetch new stats
    this.props.refreshStats();
    this.onClose();
  }

  render() {
    const { title, isOpen, controls } = this.state;
    const { iconName, responseMessage } = this.props;

    return (
      <Modal isOpen={isOpen} >
        <ModalHeader className="bg-corporate text-white">
          {iconName && <Icon name={iconName} className="mr-2" />}
          {title}
        </ModalHeader>
        <ModalBody className="m-2 p-2">
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInput handleChange={this.onHandlePeriodChange} control={controls.period_id} />
            <FormInput handleChange={this.onHandleTypeChange} control={controls.type_id} />
            {(controls.type_id.value === 'selective_group' ||  controls.type_id.value === 'selective_group_3yr')&& (
              <FormInput handleChange={this.handleChange} control={controls.group_id} />
            )} 
            {controls.type_id.value === 'selected_properties' && (
              <>
                <FormInput handleChange={this.onHandlePropertySelect} control={controls.property_id} />
                <div className="d-flex float mt-2">{this.renderProperties()}</div>
              </>
            )}
            <div className="mt-2 d-flex justify-content-start flex-row">
              <small style={{marginTop: 1}} >Overwrite Cache</small>
              <FormInputSwitch
                handleChange={this.onHandleIgnoreCache}
                control={controls.ignore_cache}
                size="sm"
                className=""
              />
            </div>
            <small className="text-success">Turn on Overwrite if underlying property data have been changed in the last 24hours. If not, leave it off for faster performance.</small>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onCreate}>
              Schedule Now
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ analysis_periods, analysis_groups, warehouse }) => ({
  analysis_periods,
  analysis_groups,
  warehouse
});

export default connect(mapStoreToProps)(AdminProbeSchedulerModal);
