import { get } from 'jsx/lib/genericAction';

import {
    apiWarehouse,
} from 'jsx/constants/api';

export function schedule3yrTop20(params) {
  return async (dispatch) => get(dispatch, 'SCHEDULE_GROUP_METRICS_3YR_TOP20', `${apiWarehouse}/metrics/averages/3yearavgtop20`, params);
}

export function scheduleTop20(params) {
  return async (dispatch) => get(dispatch, 'SCHEDULE_GROUP_METRICS_TOP20', `${apiWarehouse}/metrics/averages/top20`, params);
}

export function scheduleGroup1year(params) {
  return async (dispatch) => get(dispatch, 'SCHEDULE_GROUP_METRICS_1YR', `${apiWarehouse}/metrics/averages/group1year`, params);
}

export function schedulePropertyProbes(params) {
  return async (dispatch) => get(dispatch, 'SCHEDULE_PROPERTIES_PROBE_METRICS', `${apiWarehouse}/metrics/properties`, params);
}

export function scheduleAllPropertyProbes3yr(params) {
  return async (dispatch) => get(dispatch, 'SCHEDULE_ALL_PROPERTIES_PROBE_3YR_METRICS', `${apiWarehouse}/metrics/properties/3yearavg`, params);
}

export function terminateTasks() {
  return async (dispatch) => await get(dispatch, `RESET WAREHOUSE TASKS`, `${apiWarehouse}/terminate`);
}

export function fetchStats() {
  return async (dispatch) => await get(dispatch, `FETCH_WAREHOUSE_STATS`, `${apiWarehouse}/stats`);
}

export function fetchLogs(params) {
  return async (dispatch) => await get(dispatch, `FETCH_WAREHOUSE_LOGS`, `${apiWarehouse}/batches/logs`, params);
}

export function fetchBatches(params) {
  return async (dispatch) => await get(dispatch, `FETCH_WAREHOUSE_BATCHES`, `${apiWarehouse}/batches`, params);
}
