import Icon from 'jsx/components/core/icons/Icon';

export const BenchmarkDataRow = ({
  categoryName = '',
  metricName = '',
  colour = '',
  showSubProbesIcon = false,
  results = [],
}) => (
  <tr>
    <td className="font-weight-bold">{categoryName}</td>
    <td className={`text-${colour}`}>{metricName}</td>
    {results.map((result, index) => {
      const bgColour =  (result.value === false ? 'bg-gray-20' : '');
      const iconName = (index < 3 ? '' : 'chart-line');

      return (
        <td key={index} className={`text-right text-${colour} ${bgColour}`}>
          {result.value}
          { result.value && showSubProbesIcon && (
            <Icon
              name={iconName}
              title="Benchmark Sub Probes"
              className="ml-2 text-primary"
              onClick={result.iconFn}
              style={{cursor: 'pointer'}}
            />
          )}
        </td>
      );
    }

    )}
  </tr>
);
